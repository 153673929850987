/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: Hind, Arial, sans-serif;
	--fontFamilySecond: Khand, Arial, sans-serif;
	--primaryColor: #C51A20;
	--textColor1: #000;
	--background: #F9F9F9;
	--background2: #202020;
	--background3: #ffffff;
	--background4: #525252;
	--iconErrorColor: #F44336;
	--textColor2: #E1FF67;
	--textColor3: #202020;
	--textColor4: #ffffff;
	--textColor5: #848484;
	--textColor6: #BEBEBE;
	--textColor7: #000000;

}

html, body {
	background: var(--background);
	min-height: 100%;
	height: 100%;
	color: var(--textColor1);
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--fontFamilyBase);
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilySecond);
	font-weight: 700;
}

h1 {
	font-size: 32px;
	line-height: 38px;
}

h2 {
    font-size: 28px;
	line-height: 20px;
}

h3 {
    font-size: 24px;
	line-height: 28px;
}

h4 {
    font-size: 20px;
    line-height: 1;
}

h5 {
	font-family: var(--fontFamilyBase);
	font-size: 18px;
	line-height: 20px;
}

h6 {
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}
